import React, {  useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Table, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { getError, maxitemsPerPage } from '../../utils';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination';
import { BsPlusCircle } from 'react-icons/bs';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import moment from 'moment';
import debtService from '../../services/debt.service';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			debts: action.payload,
			loading: false,
			itemQuantity: action.payload.length
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function DebtsList() {
	const [{ loading, error, debts, successDelete, itemQuantity }, dispatch] =
		useReducer(reducer, {
			debts: [],
			loading: true,
			error: '',
			itemQuantity: 0
		});

	const navigate = useNavigate();

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = debts.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = debts.slice(indexOfFirstPost, indexOfLastPost);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const data = await debtService.getAllDebts();
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	async function newPaymentHandler() {
		navigate('/AdminScreen/debts/newpayment/all');
	}

	async function goToPaymentsHandler() {
		navigate('/AdminScreen/debts/payments');
	}

	// async function userAttendeesHandler(user) {
	//   navigate(`/AdminScreen/userattendees/${user._id}`);
	// }

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Deudas</title>
			</Helmet>

			<div className="container admin-con">
				<div className="tableResponsive">
					<Row style={{ margin: '5% 0 2.5%' }}>
						<Col>
							<h1 className="text-right">
								<FaFileInvoiceDollar></FaFileInvoiceDollar>Deudas
							</h1>
						</Col>
						<Col
							style={{
								display: 'flex',
								justifyContent: 'flex-end'
							}}
						>
							<Button
								className="btn btn-dark m-1 fixed-right"
								value="Crear Pago"
								onClick={() => newPaymentHandler()}
							>
								<span>
									<BsPlusCircle /> Registrar Pago
								</span>
							</Button>
							<Button
								className="btn btn-dark m-1 fixed-right"
								value="Crear Pago"
								onClick={() => goToPaymentsHandler()}
							>
								<span> Ver Pagos</span>
							</Button>
							<Button
								className="btn btn-dark m-1 fixed-left"
								value="Volver"
								onClick={() => navigate(-1)}
							>
								Volver
							</Button>
						</Col>
					</Row>
					{loading ? (
						<LoadingBox></LoadingBox>
					) : error ? (
						<MessageBox variant="danger">{error}</MessageBox>
					) : (
						<Card>
							<Table bordered hover responsive size="sm">
								<thead>
									<tr>
										<th className="col-md-2">
											<span>Usuario</span>
										</th>
										<th className="col-md-2">Concepto</th>
										<th>Monto</th>
										<th className="col-md-1">Fecha</th>
										<th>Pago</th>
										{/* <th className='col-1'>Opciones</th> */}
									</tr>
								</thead>
								{debts
									? currentPosts.map((debt) => (
										<tbody key={debt._id}>
											<tr
												className="align-items-center table-order"
												id="data"
												key={debt._id}
											>
												<td className="col-md-2">{debt.user?.name}</td>
												{debt.userbooking ? (
													<td className="col-md-2">{debt.userbooking.template.name}</td>
												) : (
													<td></td>
												)}
												{debt.userbooking ? (
													<td className="col-md-2">${debt.userbooking.template.price}</td>
												) : (
													<td></td>
												)}
												<td className="col-md-1">
													{moment(debt.date).format('DD-MM-YYYY hh:mm a')}
												</td>
												<td className="col-md-1">
													{debt.paid ? 'confirmado' : 'pendiente'}
												</td>
												{/* <td>
                        <DropdownButton id='optionsLists' drop='start' title=''>
                        <Dropdown.Item eventKey="0" onClick={() => detailsUserHandler(user)}><BsFileEarmarkRichtext />Ver Detalles</Dropdown.Item>
                        <Dropdown.Item eventKey="1" onClick={() => editUserHandler(user)}><BsPencilSquare />Editar</Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={() => userAttendeesHandler(user)}><BsFillCalendarEventFill />Ver Inscripciones</Dropdown.Item>
                        <Dropdown.Item eventKey="3" onClick={() => deletePaymentHandler(payment)}><BsTrash />Eliminar</Dropdown.Item>
                        </DropdownButton>
                      </td> */}
											</tr>
										</tbody>
									))
									: ''}
							</Table>
						</Card>
					)}
					<Pagination
						className="pagination-bar"
						totalCount={itemQuantity}
						onPageChange={onPageChange}
						currentPage={currentPage}
						pageSize={maxitemsPerPage}
					></Pagination>
				</div>
			</div>
		</div>
	);
}

export default DebtsList;
