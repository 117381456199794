import React, { useState } from 'react';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Navigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import './MpFailureScreen.css';
import _order from '../../services/order.service';

function MpFailureScreen() {
	const params = useParams();
	const { id } = params;
	const [updated, setUpdated] = useState(false);

	const update = async () => {
		try {
			await _order.rejectMercadoPagoOrder(id);
			setTimeout(() => {
				handleUpdate();
			}, 2000);
		} catch (ex) {
			toast.error('Error al procesar el pago.');
			console.error(ex);
		}
	};

	useEffect(() => {
		update();
	}, []);
	const handleUpdate = () => {
		setUpdated(true);
		toast.error('Pago rechazado.');
	};

	return (
		<div id="payment-failure-screen">
			{updated && <Navigate to={`/order/${id}`}></Navigate>}
			<Helmet>
				<title>Procesando pago</title>
			</Helmet>
			<Row className="justify-content-center">
				<Col xs={6}>
					<Row className="justify-content-center">
						<Col xs = {12}>
							<div className="text-center payment-status-message">
            Su pago esta siendo procesado. Por favor no cierre esta ventana.
							</div> 
						</Col>
					</Row>
					<Row className="justify-content-center mt-4">
						<Col xs = {2} >
							<i className="fas fa-spinner"></i>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
}

export default MpFailureScreen;
