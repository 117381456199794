import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import _utils from '../../../services/utils.service';
import PaymentMethod from '../../../classes/PaymentMethod';
import TabLoadingOverlay from '../../../../../components/TabLoadingOverlay/TabLoadingOverlay';

export default function PaymentMethodEditor({
	show,
	handleClose,
	selectedPaymentMethod,
	onSuccess
}) {
	const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.createEmpty());
	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
		if (selectedPaymentMethod) {
			setPaymentMethod(PaymentMethod.create(selectedPaymentMethod));
		} else {
			setPaymentMethod(PaymentMethod.createEmpty());
		}
	}, [selectedPaymentMethod]);

	function changePaymentMethodValue(key, value) {
		setPaymentMethod(PaymentMethod.create({ ...paymentMethod, [key]: value }));
	}

	const saveNewMethod = async () => {
		if (!methodIsMp() && paymentMethod.isValid()) {
			setLoading(true);
			try {
				const { method, details, active, uploadAttachment, chargePercent, usesPaymentGateway } = paymentMethod;
				await _utils.createMethod(method, details, active, uploadAttachment, chargePercent, usesPaymentGateway);
				toast.success('Metodo creado');
				onSuccess();
			} catch (ex) {
				toast.error('Error. Intentelo de nuevo.');
				console.error(ex);
			} finally {
				setLoading(false);
			}
		} else if (methodIsMp()) {
			toast.error('Error. MercadoPago esta integrado por defecto.');
		} else {
			toast.error('Error. Verifique los datos.');
		}
	};

	const handleEditMethod = async () => {
		if (paymentMethod.isValid()) {
			setLoading(true);
			try {
				const { _id, method, details, active, uploadAttachment, chargePercent, usesPaymentGateway } = paymentMethod;
				await _utils.editMethod(_id, method, details, active, uploadAttachment, chargePercent, usesPaymentGateway);
				toast.success('Cambios guardados.');
				onSuccess();
			} catch (ex) {
				toast.error('Error');
				console.error(ex);
			} finally {
				setLoading(false);
			}
		} else {
			toast.error('Error. Revise los datos');
		}
	};
	const methodIsMp = () => {
		return paymentMethod.method.toLowerCase() === 'mercadopago' || paymentMethod.method.toLowerCase() === 'mercadopagocuotas';
	};

	return (
		<Modal show={show} onHide={handleClose} animation={false} dialogClassName="modal-pay">
			<Modal.Header closeButton>
				<Modal.Title>{selectedPaymentMethod ? 'Editar metodo' : 'Nuevo metodo'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{!loading			?	
					<Form className="row justify-content-start align-items-end" onSubmit={(e) => e.preventDefault()}>
						<Form.Group className="col-6 mb-2" controlId="newMethod">
							<Form.Control
								onChange={(e) => changePaymentMethodValue('method',e.target.value)}
								required
								readOnly={selectedPaymentMethod && methodIsMp()}
								placeholder="Método"
								value={paymentMethod.method}
							></Form.Control>
						</Form.Group>
						<Form.Group className="col-6 mb-2" controlId="newActive">
							<Form.Check
								type="checkbox"
								label="Activo"
								checked={paymentMethod.active}
								onChange={(e) => changePaymentMethodValue('active',e.target.checked)}
							/>
						</Form.Group><br />
						<div className="row mb-2 mt-2">
							<Form.Group className="col-5" controlId="newComprobante">
								<Form.Check
									type="checkbox"
									label="Comprobante"
									checked={paymentMethod.uploadAttachment}
									onChange={(e) => changePaymentMethodValue('uploadAttachment',e.target.checked)}
									disabled={selectedPaymentMethod && methodIsMp()}
								/>
							</Form.Group>
							<Form.Group className="col-6 d-flex align-items-center" controlId="newChargePercent">
								<Form.Label className="col-6 mb-0">% Comisión: </Form.Label>
								<Form.Control
									className="col-5"
									type="number"
									min="0"
									max="100"
									step="0.01"
									value={paymentMethod.chargePercent}
									onChange={(e) => changePaymentMethodValue('chargePercent',e.target.value)}
									style={{ width: '60%' }}
								/>
							</Form.Group>
						</div>
						<Form.Group className="col-11" controlId="newDetails">
							<Form.Control
								onChange={ (e) => changePaymentMethodValue('details',e.target.value)}
								value={paymentMethod.details}
								readOnly={ selectedPaymentMethod && methodIsMp()}
								className="payment-details"
								as="textarea"
								placeholder="Detalles"
							></Form.Control>
						</Form.Group>
					</Form> 
					:
					<TabLoadingOverlay />
				}
			</Modal.Body>
			<Modal.Footer className="justify-content-end">
				<Button
					className="m-0"
					variant="primary"
					onClick={selectedPaymentMethod ? handleEditMethod : saveNewMethod}
				>
					Guardar
				</Button>
			</Modal.Footer>
		</Modal >
	);
}
