import restClient from '../restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const RESULT_API_URL = '/exerciseResult';

class ExerciseResultService {
	async createResults(event, wod, wodExercises,user) {
		try {
			const response = await restClient.post(
				RESULT_API_URL,
				{
					event,
					wod,
					wodExercises,
					user,
				},
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getResultsByUserId(userId) {
		try {
			const response = await restClient.get(`${RESULT_API_URL}/user/${userId}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new ExerciseResultService();
