import React, { useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import './SideCart.css';
import { round2 } from '../../utils'; 
import { useNavigate } from 'react-router-dom';
import { PiShoppingCartFill } from 'react-icons/pi';
import CartItem from './CartItem/CartItem';
import { toast } from 'react-toastify';
import { Store } from '../../../../Store';
import { Badge } from 'react-bootstrap';

export default function SideCart() {
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { cart, userInfo } = state;
	const { cartIsOpen } = cart;

	const closeCart = () => ctxDispatch({ type: 'CLOSE_CART' });
	const navigate = useNavigate();
	const [isScrolled, setIsScrolled] = useState(false);
	const bodyRef = useRef(null);    
	const removeItemHandler = (item) => {
		ctxDispatch({ type: 'CART_REMOVE_ITEM', payload: item });
	};
	const handleScroll = () => {
		setIsScrolled(bodyRef.current.scrollTop > 0);
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Escape') {
			closeCart();
		}
	};

	const checkoutHandler = async () => {
		if(cart.cartItems.length > 0) {
			// const checkoutScreen = !usesShipping ? 'cart/checkout/payment' : 'cart/checkout/shipping';
			const checkoutScreen = '/cart/checkout/payment';
			navigate(userInfo ? checkoutScreen : 'cart/checkout/login');
			closeCart();
		} else {
			toast.error('No tienes ningún producto en tu carrito.');
		}
	};
    
	useEffect(() => {
		if (cartIsOpen) {
			window.addEventListener('keyup', handleKeyPress);
			if (bodyRef) {
				bodyRef.current.addEventListener('scroll', handleScroll);
			}
		} else {
			window.removeEventListener('keyup', handleKeyPress);
		}
		return () => {
			window.removeEventListener('scroll', handleScroll);
			window.removeEventListener('keyup', handleKeyPress);
		};
	}, [cartIsOpen, bodyRef, handleKeyPress]);

	return (
		<>
			<div id='side-cart-overlay' onClick={closeCart}
				className={classnames('', { 'd-block': cartIsOpen })}>
			</div>
			<div id="side-cart" className={classnames('', { show: cartIsOpen })}>
				<div className={classnames('header', { scrolled: isScrolled })}>
					<h1 className='m-0 "font-weight-light my-cart-title'>
                        Mi Carrito <span >
							{/* ${cart.cartItems.reduce((a, c) => a + c.quantity, 0)} */}
						</span>
					</h1>
					<div style={{display: 'flex'}}>
						<div style={{alignSelf:'center'}}>
						</div>
						<div className='cart-button-container'>	
							{cart.cartItems.length > 0 && (
								<Badge pill bg="danger" className="align-self-end d-block cart-badge">
									{cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
								</Badge>
							)}
							<button className="cart-button" onClick={closeCart}>
								<PiShoppingCartFill color='white' className='cart-icon'/>
							</button>
						</div>						
					</div>
				</div>
				<div className="cart-line"></div>
				<div className='body' ref={bodyRef}>
					{
						cart.cartItems.toReversed().map((item, i) => (
							<CartItem canChangeQuantity canDeleteItem key = {item._id + i} item={item}  removeItemHandler={removeItemHandler}/>

						))
					}
				</div>
				<div className="cart-footer flex-column">
					<div className="d-flex justify-content-between pt-1">
						<span>Subtotal: ${round2(cart.cartItems.reduce((a, c) => a + c.price * c.quantity, 0))}</span>
					</div>
					<div className="d-flex justify-content-around pb-3">
						<button className='go-back-btn' onClick={closeCart}>
                            Seguir comprando
						</button>
						<button className='complete-purchase-btn' onClick={checkoutHandler}>
                            Finalizar compra
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
