import React, { useContext, useEffect, useState, useReducer } from 'react';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { useNavigate } from 'react-router-dom';
import { IoChevronBack } from 'react-icons/io5';
import _results from '../../services/results.service';
import _exercises from '../../services/exercise.service';
import _exerciseResults from '../../services/exerciseResult.service';
import _rms from '../../services/rm.service';
import './UserResultsList.css';

import { getError } from '../../utils';
import { DateTime } from 'luxon'; 
import {
	Table
} from 'react-bootstrap';


const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			results: action.payload,
			loading: false,
			itemQuantity: action.payload.length
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	}
};

function UserResultsList() {
	const { state } = useContext(Store);
	const { userInfo } = state;

	const [
		{ loading, results, error },
		dispatch
	] = useReducer(reducer, {
		results: [],
		loading: true,
		itemQuantity: 0,
		error: ''
	});

	const navigate = useNavigate();
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 576); 
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, [userInfo]);
	
	const [filteredResults, setFilteredResults] = useState([]);
	const [activeTab, setActiveTab] = useState('history');

	const [rms, setRms] = useState([]); 
	const [resultsByExercise, setResultsByExercise] = useState([]); 
	
	const [exercises, setExercises] = useState([]); 
	const [selectedExercise, setSelectedExercise] = useState(''); 

	const [loadingTable, setLoadingTable] = useState(true);

	const fetchData = async (idUser ) => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });	
			const data = await _results.gettResultsByUserId(idUser);
			dispatch({ type: 'FETCH_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	const fetchExercises = async () => {
		try {
			const data = await _exercises.getExercises(); 
			setExercises(data);
		} catch (err) {
			console.error(getError(err));
		}
	};

	const fetchRms = async () => {
		setLoadingTable(true);
		try {
			const data = await _exerciseResults.getResultsByUserId(userInfo._id); 
			setResultsByExercise(data);
			setLoadingTable(false);
		} catch (err) {
			console.error(getError(err));
		}
	};


	const fetchResultsByExercise = async () => {
		setLoadingTable(true);
		try {
			const data = await _rms.getRmsByUserId(userInfo._id); 
			setRms(data);
			setLoadingTable(false);
		} catch (err) {
			console.error(getError(err));
		}
	};




	useEffect(() => {
		fetchData(userInfo._id);
		fetchExercises();
		fetchRms();
		fetchResultsByExercise();
	}, [userInfo]);

	const groupByExercise = (results) => {
		return results.reduce((acc, result) => {
			const exerciseName = result.exercise?.name || 'Unknown Exercise';
			if (!acc[exerciseName]) {
				acc[exerciseName] = [];
			}
			acc[exerciseName].push(result);
			return acc;
		}, {});
	};

	
	const handleTabClick = (tab) => {
		setLoadingTable(true);
		setActiveTab(tab);
		if(tab === 'rms'){
			setFilteredResults(rms);
		} else if(tab === 'exercises'){
			const orderedRsults = groupByExercise(resultsByExercise);
			setFilteredResults(orderedRsults);
		} else{
			setFilteredResults([results]);
		}
		setLoadingTable(false);
	};

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		
		<div id='user-details-screen' className='admin-con perfil-con' style={{marginLeft: '5px', width: '100%'}}>
			<div className="profile-membership-header pointer" onClick={() => navigate('/profile')}>
				<span  className="back-button">
					<IoChevronBack />
				</span>
				<span>Mis Datos</span>
			</div>
			{isMobile ? 
				<div className="screen-locator" style={{ display: 'flex', alignItems: 'center' }}>
					<span>Cargar Resultados</span>
				</div> : null
			}


			<div id="user-details-screen" className="profile-membership-list">

				{/* Tabs */}
				<div className="profile-membership-tabs">
					<button 
						className={`profile-membership-tab ${activeTab === 'history' ? 'active' : ''}`} 
						onClick={() => handleTabClick('history')}
					>
						Historial
					</button>
					<button 
						className={`profile-membership-tab ${activeTab === 'exercises' ? 'active' : ''}`} 
						onClick={() => handleTabClick('exercises')}
					>
						Ejercicio
					</button>
					<button 
						className={`profile-membership-tab ${activeTab === 'rms' ? 'active' : ''}`} 
						onClick={() => handleTabClick('rms')}
					>
						RM
					</button>
				</div>

				{activeTab === 'exercises' ? (
					<div className='mx-4 mt-2'>
						<select 
							id="exercise-select" 
							value={selectedExercise} 
							onChange={(e) => setSelectedExercise(e.target.value)}
						>
							<option value="">Seleccione un ejercicio</option>
							{exercises.map((exercise) => (
								<option key={exercise._id} value={exercise._id}>
									{exercise.name}
								</option>
							))}
						</select>
					</div>
				) :null
				}

				<div id='results-table-container'>
					{/* Results Table */}
					{loadingTable ? (
						<LoadingBox></LoadingBox>
					) : error ? (
						<MessageBox variant="danger">{error}</MessageBox>
					) : filteredResults.length ? (
						(activeTab === 'rms' ?
						 (
								<Table bordered hover responsive size="sm">
									<thead>
										<th>Ejercicio</th>
										<th>Peso</th>
										<th>Repeticiones</th>
										<th>Fecha</th>
										<th></th>
									</thead>
									<tbody>
										{filteredResults.map((rm) => {
											return <tr key={rm._id}>
												<td>{rm.exercise?.name}</td>
												<td>{rm.weight}</td>
												<td>{rm.maxRep}</td>
												<td>{rm.date}</td>
												{/* <td>
										<DropdownButton id="optionsLists" drop="start" title="">
											<Dropdown.Item
												eventKey="1"
												onClick={() => editRmHandler(rm)}
											>
												<BsPencilSquare />
													Editar
											</Dropdown.Item>
											<Dropdown.Item
												eventKey="2"
												//onClick={() => toggleUserrmHandler(rm)}
											>
												<BsTrash />
													Eliminar
											</Dropdown.Item>
										</DropdownButton>
									</td> */}
											</tr>;
										})}
									</tbody>
								</Table>

							): 
							(activeTab === 'exercises' ? (
								<Table bordered hover responsive size="sm">
									<thead>
										<tr>
											<th>Ejercicio</th>
											<th>Peso</th>
											<th>Cantidad de repeticiones</th>
											<th>Fecha</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{Object.entries(resultsByExercise).map(([exerciseName, results]) => (
											<React.Fragment key={exerciseName}>
												<tr>
													<td colSpan="5" style={{ fontWeight: 'bold' }}>{exerciseName}</td>
												</tr>
												{results.map((rm) => (
													<tr key={rm._id}>
														<td>{rm.exercise?.name}</td>
														<td>{rm.weight}</td>
														<td>{rm.reps}</td>
														<td>{DateTime.fromISO(rm.createdAt).toFormat('dd/MM/yyyy')}</td>
													</tr>
												))}
											</React.Fragment>
										))}
									</tbody>
								</Table>

							): ( 	<Table bordered hover responsive size="sm">
								<thead>
									<tr>
										<th>Fecha</th>
										<th>Nombre</th>
										<th>Resultados</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{filteredResults.map((rm) => {
										return <tr key={rm._id}>
											<td>{rm.eventWod?.event.eventStartDate}</td>
											<td>{rm.eventWod?.wod.name}</td>

											<td>Ver</td>
										</tr>;
									})}
								</tbody>
							</Table>)))
					) : 
						<MessageBox>
					No hay marcas registradas para este usuario
						</MessageBox >
					}

				</div>


			</div>

		</div>
	);
}
export default UserResultsList;