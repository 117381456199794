import React from 'react';
import './LandingPageScreen.css';
import { sections } from './nonLoggedSections';
import { useNavigate } from 'react-router-dom';

const LandingPageScreen = () => {
	const navigate = useNavigate();
	const browserIsSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	return (
		<div id='landing-page-screen'>
			{
				Object.values(sections).map((section, i) => {
					return <section className={section.className} id={section.id} style={{ backgroundImage: `url(${section.image})` }}
						key={`${i}${section.id}`}>
						{section.overlay && !browserIsSafari &&
							<>
								<div className="section-top-overlay">

								</div>
								<div className="section-overlay">
								</div>
							</>
						}
						<div className="body">
							{
								section.content.map((content, j) => {
									return <>
										{React.createElement(content.tag,
											{ key: `${j + 1}${content.caption}`, className: content.shortCaption ? 'long-caption' : 'caption' }, content.caption)}
										{React.createElement(content.tag, {
											key: `${j + 2}${content.shortCaption}`,
											className: 'short-caption'
										}, content.shortCaption)}
									</>;
								})
							}
							{section.buttonCaption &&
								<button onClick={() => navigate(section.to)}>
									{section.buttonCaption}
								</button>
							}
							{
								section.linkCaption &&
								<a>
									{section.linkCaption}
								</a>
							}
						</div>
					</section>;
				})
			}
			{
				!browserIsSafari &&
				<style>{`
					#landing-page-screen {
						scroll-snap-type: y mandatory;
						scroll-behavior: smooth;
					}
					#landing-page-screen section {
						scroll-snap-align: start;
						scroll-snap-stop: always;
					}
				`}</style>
			}
		</div>
	);
};

export default LandingPageScreen;