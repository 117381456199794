import React, {  useState, useEffect } from 'react';
import {
	Form,
	Modal,
	Row,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getError } from '../../utils';
import membershipService from '../../services/membership.service';
import { BsArrowLeftCircle } from 'react-icons/bs';
import moment from 'moment';
import Membership from '../../classes/Membership';


function EditMembership({ membershipParam, onClose, onSuccess, show, showLoadTabAnimation, hideLoadTabAnimation }) {

	const [membership, setMembership] = useState(Membership.createEmptyMembership());


	useEffect(() => {
		if(membershipParam) {
			setMembership(Membership.fromObject(membershipParam));
		}
	},[membershipParam]);

	const setMembershipData = (field, value) => { 
		setMembership(Membership.fromObject({ ...membership, [field]: value }));
	};

	const editUserHandler = async () => {
		if(membership.isBasicDataValid()) {
			showLoadTabAnimation();
			try {
				const updatedData = {
					bookingsLeft: membership.bookingsLeft,
					dueDate: membership.dueDate
				};
	
				onClose();
	
				await membershipService.edit(
					membership._id,
					updatedData
				);
				toast.success('Cuponera para el usuario actualizada');
				onSuccess();
	
			} catch (err) {
				toast.error(getError(err));
			} finally {
				hideLoadTabAnimation();
			}
		} else {
			toast.error('Por favor, complete los campos correctamente');
		}
	};

	return (
		<>
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal show = {show} dialogClassName='edit-membership'>
				<Modal.Header>
					<Modal.Title>Editar cuponera</Modal.Title>
				</Modal.Header>
				<Modal.Body>			
					<Form onSubmit={(e) => e.preventDefault()}>
						<Form.Group className="mb-3" controlid="name">
							<Form.Label>Cantidad de clases:</Form.Label>
							<Form.Control
								type="text"
								required
								defaultValue={membership.bookingsLeft}
								onChange={(e) => setMembershipData('bookingsLeft', e.target.value)}
							></Form.Control>
						</Form.Group>
						<Form.Group className="mb-3" controlid="duedate">
							<Form.Label>Fecha de vencimiento:</Form.Label>
							<Form.Control
								type="date"
								required
								value={moment(membership.dueDate).format('YYYY-MM-DD')}
								onChange={(e) => setMembershipData('dueDate', moment(e.target.value))}
							></Form.Control>
						</Form.Group>

						<Row className='justify-content-around'>
							<button className='admin-button dark fit-content' onClick={onClose}>
								<BsArrowLeftCircle /> Atras
							</button>

							<button className="admin-button fit-content" onClick={editUserHandler}>
								Guardar Cambios
							</button>
						</Row>
					</Form>
				</Modal.Body>
			</Modal>

		</>
	);
}
export default EditMembership;
