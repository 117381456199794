import membershipService from '../../../services/membership.service';
import React, { useEffect, useReducer, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
	Row,
	Col,
	Button,
	Table,
	Dropdown,
	DropdownButton,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Store } from '../../../Store';
import { getError, maxitemsPerPage } from '../../../utils';
import moment from 'moment';
import Pagination from '../../../components/Pagination/Pagination';
import {
	BsPencilSquare,
} from 'react-icons/bs';
import classnames from 'classnames';
import UserMembershipAssigner from '../MembershipAssignament/UserMembershipAssigner';
import MessageBox from '../../../components/MessageBox';
import { PiProhibitLight } from 'react-icons/pi';
import { FaCheck } from 'react-icons/fa6';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import EditMembership from '../EditMembership';


const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			bookings: action.payload,
			loading: false,
			itemQuantity: action.payload.length
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function UserMembershipsList({ className, showLoadTabAnimation, hideLoadTabAnimation }) {
	const { state } = useContext(Store);
	const params = useParams();
	const { id } = params;
	const {userInfo} = state;

	const [
		{ loading, bookings, successDelete, itemQuantity },
		dispatch
	] = useReducer(reducer, {
		bookings: [],
		loading: true,
		itemQuantity: 0,
		error: ''
	});

	const [showMembershipAssigner, setShowMembershipAssigner] = useState(false);
	const [showEditMembership, setShowEditMembership] = useState(false);

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = bookings.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = bookings.slice(indexOfFirstPost, indexOfLastPost);
	};

	const fetchData = async (idUser) => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });			
			const data = await membershipService.findByUserId(idUser);
			dispatch({ type: 'FETCH_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};
	
	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			if (id == null) {
				fetchData(userInfo._id);
			}else{
				fetchData(id);
			}
		}
	}, [successDelete, id, userInfo]);

	async function toggleUserBookingHandler() {
		try {
			dispatch({ type: 'DELETE_REQUEST' });
			await membershipService.toggleMembershipStatus(selectedMembership._id);
			dispatch({ type: 'DELETE_SUCCESS' });
			toast.success(
				`Cuponera de ${selectedMembership.template.name} ${selectedMembership.isActive ? 'desactivada' : 'activada'}`
			);
		} catch (err) {
			toast.error(getError(err));
			dispatch({ type: 'DELETE_FAIL' });
		} 
	}

	
	async function assignMembershipHandler() {
		setShowMembershipAssigner(true);
	}
	async function onSuccessAssignMembership() {
		setShowMembershipAssigner(false);
		if (id == null) {
			fetchData(userInfo._id);
		}else{
			fetchData(id);
		}
	}
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [description, setDescription] = useState('');
	const [title, setTitle] = useState('');

	const [selectedMembership, setSelectedMembership] = useState({});
	const closeHandler = () => {
		setSelectedMembership({});
		setShowConfirmationModal(false);
	};
	const openModal = (booking) => {
		setTitle(`${booking.isActive ? 'Desactivar' : 'Activar'} cuponera`);
		setDescription(`¿Confirmas la ${booking.isActive ? 'desactivación' : 'activación'} de esta cuponera?`);
		setSelectedMembership(booking);
		setShowConfirmationModal(true);
	};	

	
	const handleOpenEditMembership = (membership) => { 
		setSelectedMembership(membership);
		setShowEditMembership(true);
	};
	const handleCloseEditMembership = () => {
		setSelectedMembership({});
		setShowEditMembership(false);
	};


	return <>
		<ConfirmationModal show={showConfirmationModal} description={description} 
			title={title} handleClose={closeHandler}
			handleConfirm={toggleUserBookingHandler}/>
		<UserMembershipAssigner show={showMembershipAssigner} onSuccess={onSuccessAssignMembership} handleClose={() => setShowMembershipAssigner(false)} />
		<EditMembership show = {showEditMembership} membershipParam={selectedMembership} onClose={handleCloseEditMembership}
			onSuccess={() => dispatch({ type: 'DELETE_SUCCESS' })} showLoadTabAnimation={showLoadTabAnimation} 
			hideLoadTabAnimation={hideLoadTabAnimation} />
		<div className={classnames('user-membership-list', { [className]: className })}>
			<Row>
				<Col className='d-flex justify-content-end mb-2'
				>
					<Button
						className="btn btn-dark m-1 fixed-left"
						value="Volver"
						onClick={() => assignMembershipHandler()}
					>
						<span>{id ? 'Asignar Cuponera ': 'Comprar Cuponera'}</span>
					</Button>
				</Col>
			</Row>
			{bookings.length ?
				<Table bordered responsive size="sm">
					<thead>
						<th className="col-md-2">
							<span>Cuponera</span>
						</th>
						<th className="col-md-2">Clases restantes</th>
						<th className="col-md-2">Estado</th>
						<th className="col-md-2">Fecha de inicio</th>
						<th className="col-md-2">Fecha de vencimiento</th>
						<th className="col-1">Opciones</th>
					</thead>
					<tbody>
						{currentPosts.map((booking) => (
							<tr key={booking._id}
								className={`align-items-center table-order ${booking.isActive ? '' : 'table-secondary'
								}`}
								id="data"
							>
								<td className="col-md-2">{booking.template.name}</td>
								<td className="col-md-2">{booking.bookingsLeft}</td>
								<td className="col-md-2">{`${booking.isActive ? 'Activa' : 'Inactiva'}`}</td>
								<td className="col-md-2">
									{moment(booking.startingDate).format('DD/MM/YYYY')}
								</td>
								<td className="col-md-2">
									{moment(booking.dueDate).format('DD/MM/YYYY')}
								</td>
								<td>
									<DropdownButton id="optionsLists" drop="start" title="">
										<Dropdown.Item
											eventKey="1"
											onClick={() => handleOpenEditMembership(booking)}
										>
											<BsPencilSquare />
													Editar
										</Dropdown.Item>
										<Dropdown.Item
											eventKey="2"
											onClick={() => openModal(booking)}
										>
											{booking.isActive ? <> <PiProhibitLight /> Desactivar</> : <><FaCheck /> Activar</>}

										</Dropdown.Item>
									</DropdownButton>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
				: !loading ? 
					<MessageBox>
				No hay cuponeras registradas para este usuario
					</MessageBox> : 
					<></>
			}
			<Pagination
				className="pagination-bar"
				totalCount={itemQuantity}
				onPageChange={onPageChange}
				currentPage={currentPage}
				pageSize={maxitemsPerPage}
			></Pagination>
		</div>
	</>
	;
}

export default UserMembershipsList;
