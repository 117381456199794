import moment from 'moment';

export default class Membership {
	constructor({
		_id,
		user,
		template,
		bookingsLeft,
		startingDate,
		dueDate,
		purchaseDate,
		isActive = false,
		isPaid = false
	}) {
		this._id = _id;
		this.user = user;
		this.template = template;
		this.bookingsLeft = bookingsLeft;
		this.startingDate = startingDate;
		this.dueDate = dueDate;
		this.purchaseDate = purchaseDate;
		this.isActive = isActive;
		this.isPaid = isPaid;
	}

	isBasicDataValid() {
		return Number(this.bookingsLeft) >= 0 && this.dueDate && this.startingDate && moment(this.dueDate).isAfter(this.startingDate);
	}

	static createEmptyMembership() {
		return new Membership({
			user: null,
			template: null,
			bookingsLeft: 0,
			startingDate: null,
			dueDate: null,
			purchaseDate: null,
			isActive: false,
			isPaid: false
		});
	}

	static fromObject(obj) {
		return new Membership({
			_id: obj._id,
			user: obj.user,
			template: obj.template,
			bookingsLeft: obj.bookingsLeft,
			startingDate: obj.startingDate,
			dueDate: obj.dueDate,
			purchaseDate: obj.purchaseDate,
			isActive: obj.isActive,
			isPaid: obj.isPaid
		});
	}
}
