// CartRoutes.js in cart-package
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import BlockList from '../../../../screens/Blocks/BlockList.jsx';
import BlockEditor from '../../../../screens/Blocks/BlockEditor.jsx';


function BlockRoutes({userInfo}) {
	return <>
		{userInfo?.role !== 'client' ? 
			<Routes>
				<Route path="" element={<BlockList/>} />
				<Route path="/new" element={<BlockEditor/>} />
				<Route path="/edit/:id" element={<BlockEditor />} />
			</Routes>
			: <Navigate to="/" />
		}
	</>;
}

export default BlockRoutes;
