import restClient from '../restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const RESULT_API_URL = '/results';

class ResultsService {
	async createResults(event, wodExercises,user,rounds,timeSpent,maxReps,maxWeight) {
		try {
			const response = await restClient.post(
				RESULT_API_URL,
				{
					event,
					wodExercises,
					user,
					rounds,
					timeSpent,
					maxReps,
					maxWeight,
				},
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
	async getBenchmarksByUserId(userId) {
		try {
			const response = await restClient.get(`${RESULT_API_URL}/${userId}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
	async getBestResultsByUserId(userId) {
		try {
			const response = await restClient.get(`${RESULT_API_URL}/bestResults/${userId}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
	async gettResultsByUserId(userId) {
		try {
			const response = await restClient.get(`${RESULT_API_URL}/user/${userId}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new ResultsService();
