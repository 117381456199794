import React, { useEffect, useState } from 'react';
import './PaymentMethodConfig.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import { FaEye, FaTrash, FaPen } from 'react-icons/fa';
import BtnPlus from '../../components/BtnPlus/BtnPlus';
import PaymentMethodEditor from './NewPaymentMethodModal/NewPaymentMethodModal';
import { toast } from 'react-toastify';
import _utils from '../../services/utils.service';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';

export default function PaymentMethodConfig() {
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [selectedMethod, setSelectedMethod] = useState(null);
	const [activeMethod, setActiveMethod] = useState(null); // Track the currently active method
	const [loadMethods, setLoadMethods] = useState(true);
	const [show, setShow] = useState(false);
	const [deleteShow, setDeleteShow] = useState(false);

	const fetchMethods = async () => {
		if (loadMethods) {
			try {
				const methods = await _utils.listPaymentMethods();
				setPaymentMethods(methods.data);
			} catch (ex) {
				console.error(ex);
				toast.error('Error. No se pudo cargar los métodos de pago.');
			}
			setLoadMethods(false);
		}
	};

	useEffect(() => {
		fetchMethods();
	}, [loadMethods]);

	const handleClose = () => {
		setShow(false);
	};

	const onSuccess = () => {
		setShow(false);
		setLoadMethods(true);
		setActiveMethod(null);
		setSelectedMethod(null);
	};

	const openNewMethodModal = () => {
		setSelectedMethod(null);
		setShow(true);
	};
	const openEditMethodModal = () => {
		setShow(true);
		setSelectedMethod(activeMethod);
	};

	
	const handleSelectDeletion = (pm) => {
		setSelectedMethod(pm);
		setDeleteShow(true);
	};

	const handleSelectMethod = (pm) => {
		setSelectedMethod(pm);
		setActiveMethod(activeMethod === pm ? null : pm); // Toggle the active method
	};
	const deleteMethod = async () => {
		try {
			await _utils.deleteMethod(selectedMethod._id);
			setSelectedMethod(null);
			toast.success('Metodo eliminado');
			setLoadMethods(true);
		} catch (ex) {
			toast.error('Error');
			console.error(ex);
		}
	};

	return (
		<>
			<div id="payment-config">
				<ConfirmationModal show = {deleteShow} handleClose={() => setDeleteShow(false)}
					handleConfirm={deleteMethod} title='Eliminar método de pago'
					description={`¿Estás seguro de que quieres eliminar ${selectedMethod?.method}?`}/>
				<PaymentMethodEditor
					show={show}
					handleClose={handleClose}
					selectedPaymentMethod={selectedMethod}
					onSuccess={onSuccess}
				/>
				<Row>
					<Col xs={12} md={5}>
						<div className="d-block ms-1">
							<p id="label-boton" className="d-inline-block">
                Agregar nuevo metodo.
							</p>
							<BtnPlus
								className="col-1 d-inline-block mb-1 ms-2"
								id="new-method-btn"
								onClick={openNewMethodModal}
							></BtnPlus>
						</div>
						<Row>
							<Col xs={11}>
								<ListGroup id="lista-metodos">
									{!!paymentMethods.length &&
                    paymentMethods.map((pm, index) => {
                    	const isActive = activeMethod === pm;
                    	return (
                    		<ListGroup.Item key={index} id={pm.id}>
                    			<Row className="justify-content-between px-2">
                    				<Col xs={8}>{pm.method}</Col>
                    				<Col xs={4} md={2} className="d-flex justify-content-around p-0">
                    					<button
                    						onClick={() => handleSelectMethod(pm)}
                    						className="action-button"
                    					>
                    						<FaEye className={isActive ? 'icon-active' : 'icon-inactive'} />
                    					</button>

                    					<button
                    						disabled={pm.method.toLowerCase() === 'mercadopago'}
                    						onClick={() => handleSelectDeletion(pm)}
                    						className="action-button"
                    					>
                    						<FaTrash></FaTrash>
                    					</button>
                    				</Col>
                    			</Row>
                    		</ListGroup.Item>
                    	);
                    })}
								</ListGroup>
							</Col>
						</Row>
					</Col>
					{activeMethod && (
						<Col xs={11} md={6} className="mt-2">
							<div id="method-data-container">
								<Row className="mb-3 row-align-left">
									<Col xs={12}>
										<div className="data-field">
											<label className="data-label">Nombre del método</label>
											<p className="data-value">{activeMethod.method}</p>
										</div>
									</Col>
								</Row>
								<Row className="mb-3 row-align-left">
									<Col xs={12}>
										<div className="data-field">
											<label className="data-label">Detalles</label>
											<p className="data-value">{activeMethod.details || 'No details available'}</p>
										</div>
									</Col>
								</Row>
								<Row className="align-items-center mb-3 row-align-left">
									<Col xs={8}>
										<span className="field-label">Activo</span>
									</Col>
									<Col xs={2}>
										<Form.Check
											className="pe-0"
											checked={activeMethod.active}
										/>
									</Col>
									<Col xs={2} className="text-end">
										<button className="pen-edit-method" onClick={openEditMethodModal}>
											<FaPen />
										</button>
									</Col>
								</Row>
								<Row className="align-items-center mb-3 row-align-left">
									<Col xs={8}>
										<span className="field-label">Adjuntar comprobante</span>
									</Col>
									<Col xs={2}>
										<Form.Check
											className="pe-0"
											checked={activeMethod.uploadAttachment}
										/>
									</Col>
									<Col xs={2} className="text-end">
										<button className="pen-edit-method" onClick={openEditMethodModal}>
											<FaPen />
										</button>
									</Col>
								</Row>
								<Row className="align-items-center row-align-left">
									<Col xs={8}>
										<span className="field-label">% Comisión</span>
									</Col>
									<Col xs={2}>
										<span className="field-value">{activeMethod.chargePercent ? `${activeMethod.chargePercent}%` : '0%'}</span>
									</Col>
									<Col xs={2} className="text-end">
										<button className="pen-edit-method" onClick={openEditMethodModal}>
											<FaPen />
										</button>
									</Col>
								</Row>
							</div>
						</Col>
      
          
					)}
				</Row>
			</div>
		</>
	);
}
