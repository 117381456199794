import restClient from '../restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const RM_API_URL = '/rm'; 

class RmService {
	async createRm(user, exercise, maxRep, weight, date) {
		try {
			const response = await restClient.post(
				RM_API_URL,
				{
					user, 
					exercise,
					maxRep,
					weight,
					date
				},
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getRmsByUserId(userId) {
		try {
			const response = await restClient.get(
				`${RM_API_URL}/user/${userId}`,
				{
					headers: authHeader(),
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getRmById(id) {
		try {
			const response = await restClient.get(`${RM_API_URL}/${id}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async updateUserBooking(id, updatedData) {
		try {
			const response = await restClient.put(
				`${RM_API_URL}/${id}`,
				updatedData,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
    

}
export default new RmService();