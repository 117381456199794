import React from 'react';
import { DateTime } from 'luxon';
import './ProfileEventCard.css';
import { IoMdClose } from 'react-icons/io';
import DayButton from '../DatePicker/Day/Day';
import moment from 'moment';

export default function ProfileEventCard({ event, cancelHandler, handleResults }) {
	
	const format = event.eventEndDate?.includes('/') ? 'dd/M/yyyy' : 'yyyy-MM-dd';

	const AttendButton = () => {

		const today = moment();
		const isEventPast = today.isAfter(moment(event.eventStartDateTime));
		const buttonText = isEventPast ? 'Cargar Resultados' : (
			<>
				Cancelar <IoMdClose />
			</>
		);
		const handleClick = isEventPast ? handleResults : cancelHandler;

		return (
			<button
				className="class-attend-button"
				onClick={handleClick}
			>
				{buttonText}
			</button>
		);
	};
	return (
		<div className="profile-event-card">
			<div className="profile-event-info">
				<DayButton
					date={DateTime.fromFormat(event.eventStartDate,format)}
					locale='es'
				/>
				<div className="profile-event-details">
					<div className="profile-event-time">{`${event.eventStartTime} - ${event.eventEndTime}`}</div>
					<div className="event-title">{event.subject?.toUpperCase() || '-'}</div>
					<div className="event-coach">{event.coach && (
						<>
							<span>Profe: {event.coach}</span>
						</>)}
					</div>
					<div className='class-button-container'>
						<AttendButton />
					</div>
				</div>
			</div>
			<div className="event-image-container">
				<img src={event?.wods[0]?.image.fileLink || 'https://via.placeholder.com/150'} alt="Class" />
			</div>
		</div>
	);
}