import React, {useState, useReducer, useContext} from 'react';
import { Store } from '../../../../Store';
import classnames from 'classnames';
import './UserBasicData.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoPersonCircleOutline, IoMailOutline, IoCreateOutline } from 'react-icons/io5';
import { CiPhone } from 'react-icons/ci';
import EditUserModal from '../../../../components/User/EditUserModal';
import LoadingBox from '../../../../components/LoadingBox';
import userService from '../../../../services/user.service';
import { toast } from 'react-toastify';
import { getError } from '../../../../utils';
import { PiUserCircleThin } from 'react-icons/pi';



const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, user: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

export default function UserBasicData({ className, user, style, clientView =false }) {

	const { dispatch: ctxDispatch } = useContext(Store);

	const [{ loading }, dispatch] = useReducer(reducer, {
		user: {},
		loading: false,
		error: ''
	});

	const [showModal, setShowModal] = useState(false);

	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	const editUserHandler = async (userData) => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const data = await userService.updateUser(userData._id, userData.name, userData.email, userData.password);
			dispatch({
				type: 'UPDATE_SUCCESS'
			});
			ctxDispatch({ type: 'USER_UPDATE_SUCCESS', payload: data });
			localStorage.setItem('user', JSON.stringify(data));
			toast.success('Usuario actualizado Correctamente');
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL' });
			toast.error(getError(err));
		}
	};


	return (
		<>
			{loading && <LoadingBox></LoadingBox>}
			<EditUserModal
				showModal={showModal}
				handleClose={handleClose}
				user={user}
				onSave={editUserHandler} />
			<div style={{ ...style }} className={classnames('user-basic-data', { [className]: className })}>
				<Row className='h-100 w-100'>
					<div className="profile-photo">
						<PiUserCircleThin />
					</div>
					<div className="basic-data">
						{user &&
							<>
								<h2 className='text-start'>{user.name}</h2>
								{clientView ? (
									<>
										<div className='data-line'><IoMailOutline /> <span>{user.email}</span></div>
										{user.phoneNumber && <div className='data-line'><CiPhone /> <span>{user.phoneNumber}</span></div>}
										{/* Display only email and name for client view */}
									</>
								) : (
									<>
										{user.role  && <div className='data-line'><IoPersonCircleOutline /> <span>{user.role}</span></div>}
										<div className='data-line'><IoMailOutline /> <span>{user.email}</span></div>
										{user.phoneNumber && <div className='data-line'><CiPhone /> <span>{user.phoneNumber}</span></div>}
									</>
								)}
							</>}
					</div>
					{clientView ?
						<Col as={Row} className='button-col'>
							<button className='edit-button' onClick={handleShow}>
								<IoCreateOutline className='icon' />
							</button>
						</Col> : null}
				</Row>
			</div></>
	);
}