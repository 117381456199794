import React from 'react';
import './Day.css';
import classnames from 'classnames';

/**
 * Renders a button representing a day in the date picker.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {DateTime} props.date - The date object representing the day.
 * @param {Function} [props.onSelect=() => ''] - The function to be called when the day is selected.
 * @param {boolean} [props.selected=false] - Indicates if the day is selected.
 * @param {string} [props.locale='en'] - The locale to be used for displaying the day's name.
 * @returns {JSX.Element} The rendered DayButton component.
 */
export default function DayButton({ date, onSelect = () => '', selected = false, locale = 'en'}) {

	return <div className='day-container'>
		<button className={classnames('day-button', { selected })} onClick={onSelect.bind(this, date)}>
			<div className='date'>
				{date.day}
			</div>
		</button>
		<div className='name'>
			{
				date?.setLocale(locale).weekdayShort?.toUpperCase()
			}
		</div>


	</div>;
}
