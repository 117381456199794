import 'react-toastify/dist/ReactToastify.css';
import React, {
	useContext,
} from 'react';
import { Store } from '../../Store';
import './Navbar.css';
import { useLocation, Link } from 'react-router-dom';
import NavbarOptions from '../NavbarOptions/LoggedOptions/NavbarOptions';
import GotasoftLetterLogo from '../GotasoftLetterLogo';
import NonLoggedOptions from '../NavbarOptions/NonLoggedOptions/NonLoggedOptions';

// const reducer = (state, action) => {
// 	switch (action.type) {
// 	case 'FETCH_COMPANY_REQUEST':
// 		return { ...state, loading: true };
// 	case 'FETCH_COMPANY_SUCCESS':
// 		return { ...state, GotaSettings: action.payload, loading: false };
// 	case 'FETCH_COMPANY_FAIL':
// 		return { ...state, loading: false, error: action.payload };
// 	default:
// 		return state;
// 	}
// };


function Navbarr() {
	// const [reducerState, dispatch] = useReducer(reducer, {
	// 	GotaSettings: {},
	// 	loading: true,
	// 	error: ''
	// });

	// useLayoutEffect(() => {
	// 	const fetchData = async () => {
	// 		try {
	// 			const data = await settingsService.getPublicSettings();
	// 		} catch (err) {
	// 		}
	// 	};
	// 	fetchData();
	// }, []);

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;

	//#region NAVBAR COLLAPSE METHODS
	const collapse = () => {
		ctxDispatch({ type: 'CLOSE_NAV' });
	};
	const expand = () => {
		ctxDispatch({ type: 'OPEN_NAV' });
	};
	//#endregion

	const location = useLocation();
	const route = location.pathname;

	if (route.includes('AdminScreen') || (userInfo && userInfo.isAdmin)) {
		return null;
	}

	return (
		<div className='client-navbar'>
			<header className="header-admin site-header">
				<div className="logo">
					<Link className="" to="/">
						<GotasoftLetterLogo width='150' height='40'/>
					</Link>
				</div>
				<div className='options'>
					{userInfo ? 
						<NavbarOptions expand={expand} handleCollapse={collapse} userInfo={userInfo} />
						: 	
						<NonLoggedOptions/>
						
					}
				</div>
			</header>
			<div></div>
		</div>
	);
}

export default Navbarr;
