
import membershipService from '../../services/membership.service';
import React, { useEffect, useReducer, useState, useContext } from 'react';
import { Store } from '../../Store';
import { useNavigate } from 'react-router-dom';
import { IoChevronBack } from 'react-icons/io5';
import { getError } from '../../utils';
import './ProfileEventsList.css';
import moment from 'moment';
import LoadingBox from '../../components/LoadingBox';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			bookings: action.payload,
			loading: false,
			itemQuantity: action.payload.length
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function ProfileMembershipList() {
	const { state } = useContext(Store);
	const {userInfo} = state;

	const [
		{ loading, bookings, successDelete },
		dispatch
	] = useReducer(reducer, {
		bookings: [],
		loading: true,
		itemQuantity: 0,
		error: ''
	});
	const navigate = useNavigate();


	const [filteredBookings, setFilteredBookings] = useState([]);
	const [activeTab, setActiveTab] = useState('active');

	
	const fetchData = async (idUser) => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });			
			const data = await membershipService.findByUserId(idUser);
			dispatch({ type: 'FETCH_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};
	
	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData(userInfo._id);
		}
	}
	, [successDelete, userInfo]);

	useEffect(() => {
		// Filter bookings based on the active tab
		if (bookings.length > 0) {
			const filtered = bookings.filter(booking => 
				activeTab === 'active' ? booking.isActive : !booking.isActive
			);
			setFilteredBookings(filtered);
		}
	}, [bookings, activeTab]);
	
	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};


	return (
		<div id="user-details-screen" className="profile-membership-list">

			<div className="profile-membership-header pointer" onClick={() => navigate('/profile')}>
				<span className="back-button">
					<IoChevronBack />
				</span>
				<span>Perfil</span>
			</div>
	
			{/* Tabs */}
			<div className="profile-membership-tabs">
				<button 
					className={`profile-membership-tab ${activeTab === 'active' ? 'active' : ''}`} 
					onClick={() => handleTabClick('active')}
				>
					Activa
				</button>
				<button 
					className={`profile-membership-tab ${activeTab === 'history' ? 'active' : ''}`} 
					onClick={() => handleTabClick('history')}
				>
					Historial
				</button>
			</div>
	
			{/* Membership Cards */}
			<div className="membership-list">
				{loading ? (
					<LoadingBox/>
				) : (
					filteredBookings.map((membership, index) => (
						<div className="membership-card" key={index}>
							<div className="card-content">
								<div className="profile-membership-classes-number">
									<strong>{membership.template.totalBookings}</strong>
									<span> CLASES</span>
								</div>
								<div className="membership-info">
									{activeTab === 'active' ? (
										<>
											<p>VTO: {moment(membership.dueDate).format('DD/MM/YYYY')}</p>
											<p>Quedan{' '}
												<strong>
													 {membership.bookingsLeft}/{membership.template.totalBookings} 
												</strong>
												{' '}clases disponibles</p>
										</>
									) : (
										<><p>Obtenida:  {moment(membership.startingDate).format('DD/MM/YYYY')}</p><p>Vencida: {moment(membership.dueDate).format('DD/MM/YYYY')}</p></>
									)}
								</div>
							</div>
						</div>
					))
				)}
			</div>

			{/* Button to obtain new membership */}
			{activeTab === 'active' && (
				<div className="button-container">
					<button className="obtener-nueva-button"  onClick={() => navigate('/cart/cartHomeScreen')}>Obtener nueva</button>
				</div>
			)}
		</div>
	);
}
	
export default ProfileMembershipList;
