import React, {  useState, useEffect } from 'react';
import { getError } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TabLoadingOverlay from '../../components/TabLoadingOverlay/TabLoadingOverlay';
import RoutineBlock from '../../screens/Blocks/Blocks/RoutineBlock';
import { Row } from 'react-bootstrap';
import { IoChevronBack } from 'react-icons/io5';
import './BlockEditor.css';
import _block from '../../services/wod.service';
import { Block } from '../../classes/Block';


function BlockEditor() {
	const {id} = useParams();
	const [blockToEdit, setBlockToEdit] = useState(null);
	const [loading, setLoading] = useState(!!id);
	const navigate = useNavigate();

	const getWod = async () => {
		setLoading(true);
		try {
			const block = await _block.getWodById(id);
			setBlockToEdit(Block.create(block));
		} catch (error) {
			toast.error(getError(error));
		}
		setLoading(false);
	};

	useEffect(() => {
		if(id && !blockToEdit) {
			getWod();
		} else {
			setLoading(false);
		}
	}, [id, blockToEdit]);

	const editBlock = async(block) => {
		try {
			await _block.edit(block, id);
			toast.success('Bloque modificado');
			navigate('/AdminScreen/blocks');
		} catch(ex) {
			toast.error(getError(ex));
			console.error(ex);
		}
	};
	
	const createBlock = async (block) => {
		setLoading(true);
		try {
			await _block.create(block);
			toast.success('Bloque creado');
			navigate('/AdminScreen/blocks');
		} catch (ex) {
			toast.error(getError(ex));
			console.error(ex);
		}
		setLoading(false);
	};

	const editProps = {
		block: blockToEdit,
		onSubmit : editBlock,
		title: 'Modificar bloque'
	};
	const createProps = {
		onSubmit : createBlock,
		title: 'Crear bloque'
	};
	const props = id ? editProps : createProps;

	return <div id='block-editor' className='admin-con'>
		<Row  className='previous-page-row'>
			<span onClick={() => navigate('/AdminScreen/blocks')}><IoChevronBack /> Lista de bloques</span> 
		</Row>
		{!loading ? 
			<>
				<RoutineBlock {...props} />
			
			</>
			:
			<TabLoadingOverlay className='admin'/>
		}
	</div>;
}
export default BlockEditor;
