
export default class PaymentMethod {
	_id;
	method;
	details;
	active;
	usesPaymentGateway;
	uploadAttachment;
	chargePercent;


	constructor(_id, method, details, active, usesPaymentGateway, uploadAttachment, chargePercent) {
		this._id = _id;
		this.method = method;
		this.details = details;
		this.active = active;
		this.usesPaymentGateway = usesPaymentGateway;
		this.uploadAttachment = uploadAttachment;
		this.chargePercent = chargePercent;
	}
	/**
 * 
 * @returns {boolean} true if the paymentMethod is valid, false otherwise
 */
	isValid() {
		return validator(this);
	}
	static create({_id,  method, details, active, usesPaymentGateway, uploadAttachment, chargePercent}) {
		return new PaymentMethod(_id, method, details, active, usesPaymentGateway, uploadAttachment, chargePercent);
	}
	static createEmpty() {
		return new PaymentMethod('','', '', false, false, false, 0);
	}
	/**
     * 
     * @param {PaymentMethod} paymentMethod method to validate 
     * @returns {boolean} true if the paymentMethod is valid, false otherwise
     */
	static validate(method) {
		return validator(new PaymentMethod(method));
	}
}

function validator(paymentMethod) {
	return paymentMethod.method.trim().length > 0;
}