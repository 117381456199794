import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import useExercises from '../../../hooks/useExercises';
import './RoutineBlock.css';
import Exercise from '../../../classes/Exercise';
import { Block, BlockFields } from '../../../classes/Block';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Row from 'react-bootstrap/Row';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Col } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';
import { GoPlus } from 'react-icons/go';
import classnames from 'classnames';
import '../Blocks/WodDataForm/WodDataForm.css';
import { useLocation } from 'react-router-dom';



function RoutineBlock({block, title = 'Bloque', onSubmit}) {
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const isWodStateCreated = useRef(false);
	const [wodState, setWODState] = useState(Block.createEmptyWOD());

	const initExercises = () => { 
		return block && block.wodExercises ? block.wodExercises.map(e => ({
			exercise: [e.exercise?.name],
			reps: e.reps,
			weight: e.weight,
			validationErrors: {}
		})) : [{ ...Exercise.createEmpty(), validationErrors: {} }];
	};
	const [exercisesList, setExercisesList] = useState(initExercises());
	const { exercises } = useExercises(searchParams);


	// const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
	// const openEditor = () => setConfirmModalIsOpen(true);
	// const closeEditor = () => {
	// 	setConfirmModalIsOpen(false);
	// };


	const getExercisesFormErrors = () => {
		const updatedExercises = [...exercisesList];
		const exercises = updatedExercises.map(cls => {
			const { exercise, reps, weight } = cls;
			const validationErrors = {};
			if (!exercise) {
				validationErrors.exercise = 'El ejercicio es obligatorio';
			}
			if (typeof exercise != 'object') {
				validationErrors.exercise = 'Ingrese un ejercicio de la lista.';
			}
			if (!reps || isNaN(reps) || reps <= 0) {
				validationErrors.reps = 'Se requiere un número no negativo';
			}
			if (weight !== undefined && (isNaN(weight) || weight < 0)) {
				validationErrors.weight = 'Se requiere un número no negativo';
			}
			return { ...cls, validationErrors };
		});

		return exercises;
	};


	const submitWodDetails = async () => {
		const validatedExercises = getExercisesFormErrors();
		if (wodState.isValid() && Object.keys(BlockFields).includes(wodState.type)) {
			if (exercisesList.length > 0) {
				const exercisesHaveErrors = validatedExercises.some(cls => Object.keys(cls.validationErrors).length);
				setExercisesList(validatedExercises);
				if (!exercisesHaveErrors) {
					const wodExercises = exercisesList.map(e => ({ exercise: e.exercise[0]._id, reps: e.reps, weight: e.weight }));
					onSubmit(Block.create({...wodState, wodExercises }));
				} else {
					toast.error('Hay errores en los ejercicios');
				}
			} else {
				toast.error('Debes especificar al menos 1 ejercicio');
			}
		} else {
			toast.error('Debes completar la información básica');
		}
	};

	const handleAddExercise = () => {
		setExercisesList([
			...exercisesList,
			{ ...Exercise.createEmpty(), validationErrors: {} }
		]);
	};

	//remove exercise to the list in the wod
	const handleRemoveExercise = (index) => {
		const updatedExercisesList = exercisesList.filter((_, i) => i !== index);
		setExercisesList(updatedExercisesList);
	};

	const handleExerciseDetailChange = (index, field, value) => {
		const updatedExercisesList = [...exercisesList];
		updatedExercisesList[index][field] = value;
		setExercisesList(updatedExercisesList);
	};


	const setWodValue = (field, value) => {
		setWODState(Block.create({ ...wodState, [field]: value }));

	};
	
	function formatTimeInput(event) {
		let input = event.target.value.replace(/[^0-9]/g, '');
		if (input.length >= 3) {
			input = input.slice(0, 2) + ':' + input.slice(2, 4);
		}
		return input;
	}
	const getCalculatedField = (field) => {
		return wodState[field];
	};
	//#region effects
	useEffect(() => {
		if (block && !isWodStateCreated.current) {
			setWODState(Block.create(block));
			isWodStateCreated.current = true;
		}
	}, [block]);
	useEffect(() => {
		if (wodState.type && !block) {
			setWODState(Block.create({ ...Block.createEmptyWOD(), type: wodState.type, name: wodState.name }));
		}
	}, [wodState.type]);
	useEffect(() => {
		if (wodState.type) {
			const updatedWODState = { ...wodState };
			let hasChanges = false;
			Object.keys(BlockFields[wodState.type]).forEach((field) => {
				const { calculator } = BlockFields[wodState.type][field];
				if (calculator) {
					const calculatedValue = calculator(wodState);
					if (updatedWODState[field] !== calculatedValue) {
						updatedWODState[field] = calculatedValue;
						hasChanges = true;
					}
				}
			});
			if (hasChanges) {
				setWODState(Block.create(updatedWODState));
			}
		}
	}, [wodState.type, wodState]);
	//#endregion
	
	return (
		<div className='wod-editor d-flex justify-content-center w-100 p-2'>
			<div className="block-wrapper">
				<div className='block-title'>
					<span>
						{title}
					</span>
				</div>
				<Form  noValidate={true} onSubmit={(e) => e.preventDefault()} className='header row'>
					<FloatingLabel className='col-sm-6' controlId="nombre" a
						label="Nombre">
						<Form.Control value={wodState.name}
							onChange={(e) => setWodValue('name', e.target.value)} required />
					</FloatingLabel>
					<FloatingLabel label="Tipo de WOD" className='col-sm-6'>
						<Form.Select
							value={wodState.type}
							onChange={(e) => setWodValue('type', e.target.value)}
							required
						>
							<option value="" >Seleccione un tipo</option>
							{Object.keys(BlockFields).map((type, index) => (
								<option key={index} value={type}>{type}</option>
							))}
						</Form.Select>
					</FloatingLabel>
				</Form>
				<Form  noValidate={true} onSubmit={(e) => e.preventDefault()} className='body'>
					{wodState.type && <Row className="w-100 m-auto wod-data">
						{
							Object.keys(BlockFields[wodState.type]).map((field, index) => {
								const { leftLabel, rightLabel, type, calculator } = BlockFields[wodState.type][field];
								return (
									<Col sm={rightLabel || leftLabel.length > 6 ? 4 : 3} 
										md = {rightLabel || leftLabel.length > 6 ? 3 : 2} 
										key={index} className='form-field'>
										<span className='left-label'>{leftLabel}</span>
										<Form.Control
											type={type}
											placeholder={type === 'text' ? 'mm:ss' : ''}
											disabled={!!calculator}
											value={calculator ? getCalculatedField(field) : wodState[field]}
											onChange={!calculator ? (e) => setWodValue(field, type == 'text' ?
												  formatTimeInput(e) : e.target.value): null}
											required
											min={1}
										/>
										{(rightLabel || window.innerWidth <= 576) &&
											<span className='right-label'>{rightLabel}</span>
										}
									</Col>
								);
							})
						}
					</Row>}
					
				</Form>
				{	wodState.type && <div className='w-100 wod-exercises px-2 py-1'>
					{exercisesList?.length > 0 &&
						<Row className="mb-2 labels w-100 flex-nowrap">
							<Col xs = {4} className='text-center h-fit-content'>
								Ejercicio
							</Col>
							<Col xs={4} className='text-center h-fit-content'>
								Reps
							</Col>
							<Col xs={3} className='text-center h-fit-content'>
								Peso
							</Col>
							<button disabled className='text-danger w-fit-content invisible'>
								<FaRegTrashAlt />
							</button>
						</Row>}
					{
						exercisesList.map((item, index) => (
							<Form key={index} noValidate onSubmit={(e) => e.preventDefault()} className='w-100 pb-2'>
								<Row className="exercise align-items-start w-100 flex-nowrap">
									<Form.Group className="col-4">
										<Typeahead
											isValid={item.validationErrors.exercise === ''}
											id="exercise-search"
											options={exercises}
											defaultInputValue={Array.isArray(item.exercise) ? item.exercise[0] : item.exercise}
											placeholder="Seleccione un ejercicio"
											labelKey="name"
											onChange={(selected) => handleExerciseDetailChange(index, 'exercise', selected)}
										/>
										{
											item.validationErrors.exercise &&
											<div className={classnames('invalid-feedback')}>
												El ejercicio es obligatorio
											</div>
										}

									</Form.Group>
									<Form.Group className="col-4">
										<Form.Control
											type="number"
											value={item.reps}
											onChange={(e) =>
												handleExerciseDetailChange(index, 'reps', e.target.value)
											}
											required
										/>
										{
											item.validationErrors.reps &&
										<div className={classnames('invalid-feedback')}>
											Se requiere un número no negativo
										</div>
										}

									</Form.Group>
									<Form.Group className="col-3">
										<Form.Control
											type="number"
											value={item.weight}
											onChange={(e) =>
												handleExerciseDetailChange(index, 'weight', e.target.value)
											}
											required
										/>
										{
											item.validationErrors.weight && 
											<div className={classnames('invalid-feedback')}>
												Se requiere un número no negativo
											</div>
										}


									</Form.Group>
									<button onClick={() => handleRemoveExercise(index)} className='text-danger w-fit-content'>
										<FaRegTrashAlt />
									</button>
								</Row>
							</Form>
						))
					}
					<div className='d-flex m-3 ms-0'>
						<button className='add-ex-btn h-fit-content p-2' onClick={handleAddExercise}>
							Agregar ejercicio
							<GoPlus />
						</button>
					</div>
				</div>}
				<div className="footer d-flex justify-content-end w-100 p-3">
					<button className='btn btn-primary m-0' onClick={submitWodDetails}>Guardar</button>
				</div>
			</div>
		</div>
	);
}
export default RoutineBlock;
