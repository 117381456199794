import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import {Row} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ProfileMembershipsList from './UserMemberships/UserMembershipList/UserMembershipList';
import UserBookingList from './UserBookingList/UserBookingList';
import UserRms from '../components/UserRMs/UserRms';
import UserBasicData from './Admin/Users/UserBasicData/UserBasicData';
import CardTabs from '../components/CardTab/CardTab';
import './ProfileScreen.css';
import { FaRegBell } from 'react-icons/fa';
import classnames from 'classnames';
import _user from '../services/user.service';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';

const options = [
	{option: 'MIS CLASES',
		image: '../images/misClases.png',
		cmp: UserBookingList, navTag: 'my-classes'},
	{ option: 'CUPONERAS', image: '../images/cuponeras.png', cmp: ProfileMembershipsList,  navTag: 'my-cuponeras'},
	{ option: 'DATOS', image: '../images/datos.png', cmp: UserRms,  navTag: 'my-results'}
];

function ProfileScreen() {
	const { state, dispatch} = useContext(Store);
	const { userInfo } = state;

	const navigate = useNavigate();
	const [isMobile, setIsMobile] = useState(false);
	const [notificationData, setNotificationData] = useState({});
	const [toggledNotifications, setToggledNotifications] = useState(false);

	useEffect(() => {
		const triggerLoad = () => {
			dispatch({ type: 'SHOW_LOADING_OVERLAY'});
			setTimeout(() => {
				dispatch({ type: 'HIDE_LOADING_OVERLAY' });
			}, 600);
		};
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 576); 
		};
		triggerLoad();
		fetchNotificationData();
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);
	
	
	const toggleNotifications = () => { 
		setToggledNotifications(!toggledNotifications);
	};

	const fetchNotificationData = async () => {
		try {
			const {data} = await _user.getNotifications(userInfo._id);
			setNotificationData(data);
		} catch (error) {
			toast.error('No se pudo obtener las notificaciones');
			console.log(error);
		}
	};

	return (
		<div id='profile-screen' className='perfil-con'>
			<div className="header w-100">
				{isMobile ? 
					<div className="screen-locator">
						<span>Mi Perfil</span>
					</div> :null
				}		
				<UserBasicData  loading={state.loadingOverlay} user={userInfo} clientView={true} />
			</div>			
			<div className='content'>
				<div className="notification-row">
					<div className={isMobile ? 'visible' : 'invisible'}>
						<h4> HOLA {userInfo.name}! </h4>
					</div>
					
					<button className='notification-btn' onClick={toggleNotifications}>
						{(!!notificationData.bookingsToday?.length || !!notificationData.activeMemberships?.length) && <span>
						</span>}
						<FaRegBell />
					</button>
				</div>
				<Row className='options'>
					<section className='w-100'>
						<CardTabs options={options} />
					</section>
					<div className={classnames('notification-area', {open: toggledNotifications})}>
						<section>
							<h4>Cuponeras</h4>
							{
								notificationData.activeMemberships?.length ? 
									notificationData.activeMemberships.map((membership,i) => {
										return (
											<div key={i}>
												<div className='name'>{membership.template.name}</div>
												<div className='description'>{membership.bookingsLeft} clases disponibles</div>
												<div className='description'>Vto. {DateTime.fromISO(membership.dueDate).toLocaleString() }</div>
											</div>
										);
									}) : 
									<>
										<div>
									No hay cuponeras activas
										</div>
										<button className='admin-button mt-2' onClick={() => navigate('/cart/cartHomeScreen')}>Comprar</button>
									</>
							}
						</section>
						<section>
							<h4>Hoy</h4>
							{
								notificationData.bookingsToday?.length ? 
									notificationData.bookingsToday.map(ev => {
										return (
											<div key={ev._id}>
												<div className='name'>{ev.event.subject} : <span className="description">{ev.event.eventStartTime} - {ev.event.eventEndTime}</span></div>
											</div>
										);
									})
									 : 
									 <>
										<div className='name'>No hay clases hoy</div>
										<button className='admin-button mt-2' onClick={() => navigate('/events')}>Agendar</button>
									 </>
							}
						</section>
					</div>
				</Row>
			</div>
		</div>

	);
}
export default ProfileScreen;
