import Badge from 'react-bootstrap/Badge';
import React, {
	useContext,
} from 'react';
import { Store } from '../../../../Store';
import './CartNavbar.css';
import { useLocation } from 'react-router-dom';
import { PiShoppingCartFill } from 'react-icons/pi';
import SideCart from '../SideCart/SideCart';

function CartNavbar() {

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { cart } = state;
	const location = useLocation();
	const route = location.pathname;

	const openCart = () => {
		if(!route.includes('checkout')) {
			ctxDispatch({ type: 'OPEN_CART' });
		}
	};

	return (
		<div className='cart'>
			<SideCart/>
				
			<div style={{display: 'flex'}}>
				<div className='cart-button-container'>	
					<button className="cart-button" onClick={openCart}>
						<PiShoppingCartFill color='white' className='cart-icon'/>
						{cart.cartItems.length > 0 && (
							<Badge pill bg="danger" className="align-self-end d-block cart-badge">
								{cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
							</Badge>
						)}
					</button>
				</div>						
			</div>
		</div>
	);
}

export default CartNavbar;
