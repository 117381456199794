import restClient from '../restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const WOD_API_URL = '/wod'; // Assuming this is the base URL for WOD-related endpoints

class WodService {
	/**
	 * 
	 * @param {Block} block 
	 */
	async create(block) {
		try {
			const response = await restClient.post(
				WOD_API_URL, block);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
	/**
 * 
 * @param {Block} block 
 */
	async edit(block,id) {
		try {
			const response = await restClient.put(
				`${WOD_API_URL}/${id}`, block);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async uploadWodImage(formData) {
		try {
			const multiPart = { 'content-type': 'multipart/form-data' };
			const response = await restClient.post(`${WOD_API_URL}/uploadWodImg`, formData, {
				headers: { ...multiPart }
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async deleteWod(id) {
		try {
			const response = await restClient.delete(`${WOD_API_URL}/${id}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
	async getWodByName(name) {
		try {
			const response = await restClient.get(`${WOD_API_URL}/byName?name=${name}`);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
	async getAllWods() {
		try {
			const response = await restClient.get(WOD_API_URL);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getWodByDate(date) {
		try {
			const response = await restClient.get(`${WOD_API_URL}/wodday?date=${date}`);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getWodById(id) {
		try {
			const response = await restClient.get(`${WOD_API_URL}/${id}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
	async getWodByEvent(id) {
		try {
			const response = await restClient.get(`${WOD_API_URL}/byEvent/${id}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new WodService();
