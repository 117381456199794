import React, { useReducer, useState } from 'react';
import userService from '../../../../services/user.service';
import { Helmet } from 'react-helmet-async';
import {
	Button,
	Modal,
	Form,
	Container,
	Row,
	Col,
	Card,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { getError } from '../../../../utils';
import LoadingBox from '../../../../components/LoadingBox';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function AddUser() {
	const navigate = useNavigate();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [role, setRole] = useState('client');
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	const [{ loadingFetch, loadingCreate }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		loadingCreate: false,
		error: ''
	});

	const addUserHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await userService.createUser(name, email, role);
			toast.success('Usuario creado');
			navigate('/AdminScreen/users');
		} catch (ex) {
			toast.error(getError(ex));
		}
	};
	return (
		<div>
			<div>
				{loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
				<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
					<Modal.Header closeButton>
						<Modal.Title>Crear Usuario</Modal.Title>
					</Modal.Header>
					<Modal.Body>Seguro desea crear usuario?</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button onClick={addUserHandler}>Confirmar</Button>
					</Modal.Footer>
				</Modal>
			</div>
			<Container className="large-container">
				<Helmet>
					<title>Crear Usuario</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
											Crear Usuario
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group onSubmit={e => e.preventDefault()} className="mb-3" controlid="name">
													<Form.Label>Nombre</Form.Label>
													<Form.Control
														type="text"
														required
														onChange={(e) => setName(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group className="mb-3" controlid="email">
													<Form.Label>E-mail</Form.Label>
													<Form.Control
														type="email"
														required
														onChange={(e) => setEmail(e.target.value.toLowerCase())}
													></Form.Control>
												</Form.Group>

												<Form.Group className="mb-3" controlId="role">
													<Form.Label>Rol</Form.Label>
													<Form.Control
														as="select"
														value={role}
														onChange={(e) => setRole(e.target.value)}
														required
													>
														<option value="client">Cliente</option>
														<option value="system_admin">Administrador</option>
														<option value="bussiness_admin">Manager</option>
														<option value="operator">Operador</option>
														{/* Add more roles as needed based on userModel.js */}
													</Form.Control>
												</Form.Group>


												<Row className='justify-content-around'>
													<button className='admin-button dark fit-content' onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</button>

													<button className="admin-button fit-content" onClick={handleShow}>
														Crear Usuario
													</button>
												</Row>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default AddUser;
