import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Carousel, Col } from 'react-bootstrap';
import { DatePicker } from '../../../components/DatePicker/DatePicker';
import { DateTime } from 'luxon';
import './BookingScreen.css';
import Badge from 'react-bootstrap/Badge';
import classnames from 'classnames';
import GridLoader from 'react-spinners/GridLoader';
import useQueryFilters from '../../../hooks/useQueryFilters';
import EventCard from '../../../components/EventCard/EventCard';
import _event from '../../../services/event.service';
import _eventAttendee from '../../../services/eventAttendee.service';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import { Store } from '../../../Store';
import { toast } from 'react-toastify';
import { getError } from '../../../utils';
import moment from 'moment';


const classCategoriesDummy = [
	{
		key: 'all',
		label: 'Todas'
	},
	{
		key: 'open-box',
		label: 'Open Box'
	},
	{
		key: 'open-outdoor',
		label: 'Open Outdoor'
	}
];


function BookingScreen() {
	// const { dispatch } = useContext(Store);
	const initFields = {
		sort: JSON.stringify({ eventStartDateTime: 1 }),
		eventStartDateTime: DateTime.now().toISODate().toString(),
	};
	const { state } = useContext(Store);
	const userId = state.userInfo._id;
	const navigate = useNavigate();
	const { searchParams, search, submitFilters, fields } = useQueryFilters(
		{ baseUrl: '/events', resizeWidth: 768, paginate: false, initFields }
	);
	const [selectedDate, setSelectedDate] = useState(DateTime.now());
	const [selectedCategory, setSelectedCategory] = useState('all');
	const [loadingEvents, setLoadingEvents] = useState(true);

	const [events, setEvents] = useState([]);
	const [userBookings, setUserBookings] = useState([]);

	const [selectedEvent, setSelectedEvent] = useState(null);

	const fetchUserBookings = async () => {
		try {
			const data = await _eventAttendee.getUserBookings(userId);
			setUserBookings(data);
		} catch (ex) {
			toast.error('Error al obtener las reservas del usuario');
			console.error(ex);
		}
	};
	const fetchEvents = async () => {
		setSelectedEvent(null);
		try {
			const data = await _event.getFilteredEvents(searchParams);
			setEvents(data.events);
		} catch (ex) {
			toast.error(getError(ex));
			console.error(ex);
		}
	};
	const fetchData = async () => {
		setLoadingEvents(true);
		await Promise.all([fetchEvents(), fetchUserBookings()]);
		setLoadingEvents(false);
	};

	useEffect(() => {
		if (search) {
			fetchData();
		}
	}, [search]);

	const onSelectDate = (date) => {
		setSelectedDate(date);
		submitFilters({ ...fields, eventStartDateTime: date.toISODate().toString() });
	};

	const selectEvent = (event) => {
		setSelectedEvent(event);
	};

	const [showConfirmBookingModal, setShowConfirmBookingModal] = useState(false);
	const [showCancelBookingModal, setShowCancelBookingModal] = useState(false);

	const openConfirmReservationModal = () => {
		setShowConfirmBookingModal(true);
	};
	const openCancelReservationModal = () => {
		setShowCancelBookingModal(true);
	};

	const goToResultsForm = (eventId) => {
		navigate(`/results/${eventId}`);
	};

	const handleConfirmReservation = async () => {
		try {
			if (moment(selectedEvent.eventStartDateTime) >= moment()) {
				await _eventAttendee.bookEvent({
					user: userId,
					event: selectedEvent._id
				});
				toast.success('Reserva realizada con éxito');
				await fetchData();
			} else {
				toast.error('No puedes reservar una clase que ya ha pasado');
			}
		}
		catch (ex) {
			toast.error(getError(ex));
			console.error(ex);
		}
		finally {
			setShowConfirmBookingModal(false);
		}
	};
	const handleCancelReservation = async () => {
		try {
			await _eventAttendee.cancelBooking(userId,selectedEvent._id);
			toast.success('Reserva cancelada con éxito');
			await fetchData();
		}
		catch (ex) {
			toast.error(getError(ex));
			console.error(ex);
		}
		finally {
			setShowCancelBookingModal(false);
		}
	};



	return (
		<div className="client-container booking-screen">
			<Helmet>
				<title>Reservá una clase</title>
			</Helmet>
			<ConfirmationModal
				show={showConfirmBookingModal}
				handleClose={() => setShowConfirmBookingModal(false)}
				title='Confirmar reserva'
				description='¿Estás seguro de que deseas reservar esta clase?'
				handleConfirm={handleConfirmReservation}
			/>
			<ConfirmationModal
				show={showCancelBookingModal}
				handleClose={() => setShowCancelBookingModal(false)}
				title='Cancelar reserva'
				description='¿Estás seguro de que deseas cancelar esta clase?'
				handleConfirm={handleCancelReservation}
			/>
			<div className='datepicker-container'>
				<DatePicker selectedDate={selectedDate} onSelectDate={onSelectDate} />
				<div className='categories'>
					{classCategoriesDummy.map((category, index) => {
						return <Badge as={'button'} key={index} className={classnames('', { selected: selectedCategory.key === category.key })}
							onClick={() => setSelectedCategory(category)} pill
							text="dark">
							{category.label}
						</Badge>;
					})}
				</div>
			</div>
			<div className='body d-flex flex-wrap'>
				{!loadingEvents ?
					(
						events.length ?
							<><Col className='classes-column' md={7}>
								{events.map(e => {
									return <EventCard selected={selectedEvent?._id === e._id} booked={userBookings.some(ub => ub.event._id === e._id)}
										onSelect={selectEvent} bookHandler={openConfirmReservationModal} cancelHandler = {openCancelReservationModal}
										resultsHandler={() => goToResultsForm(e._id)} 
										event={e} key={e._id} />;
								})}

							</Col>
							<Col className='class-description d-md-flex d-none p-2' md={5}>
								<h1>WODS</h1>
								<Carousel>
									{
										selectedEvent?.wods ? selectedEvent.wods.map(wod => {
											return <Carousel.Item key={wod.image.fileLink}>
												<img className='wod-image' src={wod.image.fileLink} alt={wod.name} />
											</Carousel.Item>;
										}) : 
											<Carousel.Item>
												<img className='wod-image' src={'/greybg.png'}/>
											</Carousel.Item>
									}
								</Carousel>
							</Col>
							</> :
							<Col xs={12} className='d-flex justify-content-center h-75 align-items-center'>
								<div className = "no-items-msg">No hay clases disponibles</div>
							</Col>
					) :
					<Col xs={12} className='d-flex justify-content-center h-75 align-items-center'>
						<GridLoader />
					</Col>
				}
			</div>
		</div>
	);
}

export default BookingScreen;
