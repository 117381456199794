import React, { useEffect, useReducer, useState } from 'react';
import eventService from '../../../services/event.service';
import {
	Button,
	Modal,
	Form,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getError } from '../../../utils';
import LoadingBox from '../../../components/LoadingBox';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import wodService from '../../../services/wod.service';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import Event from '../../../classes/Event';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import './EventEditor.css';
import { getDatesByFrequency } from './eventEditorHelpers';
import { DateTime } from 'luxon';


const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'FETCH_SUCCESS':
		return { ...state, loadingFetch: false };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function EventEditor({ eventParam, show, onClose: closeHandler, onSuccess }) {

	const [{ loadingFetch, loadingCreate }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		loadingCreate: true,
		error: ''
	});
	const [event, setEvent] = useState(Event.createEmptyEvent());
	const [showConfirmCreateModal, setShowConfirmCreateModal] = useState(false);
	const [showConfirmEditModal, setShowConfirmEditModal] = useState(false);
	const handleCloseCreate = () => setShowConfirmCreateModal(false);
	const handleCloseEdit = () => setShowConfirmEditModal(false);

	const handleShowCreate = () => {
		if (event.isBasicDataValid()) {
			setShowConfirmCreateModal(true);
		} else {
			toast.error('Debe completar todos los campos');
		}
	};

	const handleShowEdit = () => {
		if (event.isValid()) {
			setShowConfirmEditModal(true);
		} else {
			toast.error('Debe completar todos los campos');
		}
	};

	const [wods, setWods] = useState([]);

	const [repeatOption, setRepeatOption] = useState('none');
	const [selectedDays, setSelectedDays] = useState([]);
	const availableWeekDays = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];

	useEffect(() => {
		if (eventParam) {
			setEvent(new Event(eventParam));
		} else {
			setEvent(Event.createEmptyEvent());
		}
	}, [eventParam]);

	const onClose = () => { 
		setEvent(Event.createEmptyEvent());
		setShowConfirmCreateModal(false);
		setShowConfirmEditModal(false);
		setRepeatOption('none');
		setSelectedDays([]);
		closeHandler();
	};
	const onChangeEventData = (key, value) => {
		const newEvent = new Event(event);
		newEvent[key] = value;
		setEvent(newEvent);
	};
	useEffect(() => {
		const fetch = async () => {
			try {
				const data = await wodService.getAllWods();
				setWods(data);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetch();
	}, []);

	const addEventHandler = async () => {
		dispatch({ type: 'CREATE_REQUEST' });
		const dates = getDatesByFrequency(repeatOption, event, toast, availableWeekDays, selectedDays);
		if (dates.length > 0) {
			if (event.isBasicDataValid()) {
				try {
					const eventData = {
						...event,
						dates
					};
					await eventService.createEvent(eventData);
					dispatch({ type: 'CREATE_SUCCESS' });
					toast.success('Evento creado');
					onClose();
					onSuccess();
				} catch (error) {
					dispatch({ type: 'CREATE_FAIL' });
					toast.error(getError(error));
				}
			} else {
				toast.error('Debe completar todos los campos');
			}

		} else
			toast.error(
				'Debe elegir una fecha de fin acorde y filtros que permitan ingresar al menos una fecha.'
			);
	};

	const editEvent = async () => {
		if (event.isValid()) {
			try {
				dispatch({ type: 'CREATE_REQUEST' });
				await eventService.updateEvent(event._id, event);
				dispatch({ type: 'CREATE_SUCCESS' });
				toast.success('Cambios guardados');
				onClose();
				onSuccess();
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL' });
				toast.error(getError(err));
			}

		} else {
			toast.error('Debe completar todos los campos');
		}
	};




	const handleRepeatOptionChange = (event) => {
		setRepeatOption(event.target.value);
	};

	//Cada uno de los dias de la semana entran o salen de la lista
	const handleSelectedDaysChange = (event) => {
		const day = event.target.value;
		if (selectedDays.includes(day)) {
			setSelectedDays(selectedDays.filter((d) => d !== day));
		} else {
			setSelectedDays([...selectedDays, day]);
		}
	};





	const modalStyle = {
		zIndex: 1050,
	};

	return (
		<>
			{loadingCreate || (loadingFetch && <LoadingBox></LoadingBox>)}
		
			<ConfirmationModal description='¿Estás seguro de que quieres crear esta clase?' title='Crear clase'
				show={showConfirmCreateModal} handleClose={handleCloseCreate} handleConfirm={addEventHandler} confirmCaption='Crear' />
			<ConfirmationModal description='¿Quieres guardar los cambios sobre esta clase' title='Editar clase'
				show={showConfirmEditModal} handleClose={handleCloseEdit} handleConfirm={editEvent} confirmCaption='Guardar' />
			<Modal animation={false} show={show} onHide={onClose} style={modalStyle} >
				<Modal.Header closeButton>
					<Modal.Title>{event._id ? 'Editar' : 'Crear'} clase</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form className='event-editor' onSubmit={(e) => e.preventDefault()}>

						<FloatingLabel controlId="tema" label="Tema" className="mb-3">
							<Form.Control type="text" required value={event.subject} onChange={(e) => onChangeEventData('subject', e.target.value)} placeholder="Tema" />
							<Form.Control.Feedback type="invalid">El tema es requerido</Form.Control.Feedback>
						</FloatingLabel>

						<FloatingLabel controlId="locacion" label="Ubicación" className="mb-3">
							<Form.Control type="text" required value={event.eventLocation} onChange={(e) => onChangeEventData('eventLocation', e.target.value)}
								placeholder="Ubicación" />
							<Form.Control.Feedback type="invalid">La ubicación es requerida</Form.Control.Feedback>
						</FloatingLabel>

						<FloatingLabel controlId="capacidad" label="Capacidad" className="mb-3">
							<Form.Control type="number" required defaultValue="1" min="1" value={event.capacity}
								onChange={(e) => onChangeEventData('capacity', e.target.value)} placeholder="Capacidad" />
							<Form.Control.Feedback type="invalid">La capacidad es requerida</Form.Control.Feedback>
						</FloatingLabel>

						<FloatingLabel controlId="Fecha de inicio" label="Fecha de inicio" className="mb-3">
							<Form.Control type="datetime-local" required value={DateTime.fromISO(event.eventStartDateTime, {zone: 'utc'}).toFormat('yyyy-MM-dd HH:mm') } 
							/*FORMAT REQUIRED FOR THIS TYPE OF INPUT IS yyyy-MM-dd*/
								minDate={new Date()}
								onChange={(e) => onChangeEventData('eventStartDateTime', e.target.value)} placeholder="Fecha de inicio" />
							<Form.Control.Feedback type="invalid">La fecha de inicio es requerida</Form.Control.Feedback>
						</FloatingLabel>

						<FloatingLabel controlId="duracion" label="Duración en minutos" className="mb-3">
							<Form.Control type="number" required defaultValue="30" min="30" value={event.duration} onChange={(e) => onChangeEventData('duration', e.target.value)} placeholder="Duración en minutos" />
						</FloatingLabel>
						<FloatingLabel controlId="coach" label="Profesor/a" className="mb-3">
							<Form.Control type="text" required value={event.coach} onChange={(e) => onChangeEventData('coach', e.target.value)} placeholder="Profesor/a" />
						</FloatingLabel>

						{!event._id &&
							<FloatingLabel controlId="repetir" label="Repetir" className="mb-3">
								<Form.Select
									value={repeatOption}
									onChange={handleRepeatOptionChange}
								>
									<option value="none">No se repite</option>
									<option value="daily">Todos los dias</option>
									<option value="weekday">De lunes a viernes</option>
									<option value="weekly">Semanalmente</option>
								</Form.Select>
							</FloatingLabel>
						}
						{(repeatOption === 'daily' ||
							repeatOption === 'weekday' ||
							repeatOption === 'weekly') &&
							<FloatingLabel controlId="Fecha de fin" label="Fecha de fin" className="mb-3">
								<Form.Control type="datetime-local" required value={DateTime.fromISO(event.eventEndDateTime, { zone: 'utc' }).toFormat('yyyy-MM-dd HH:mm')}
								/*FORMAT REQUIRED FOR THIS TYPE OF INPUT IS yyyy-MM-dd*/
									minDate={new Date()}
									onChange={(e) => onChangeEventData('eventEndDateTime', e.target.value)} placeholder="Fecha de fin" />
								<Form.Control.Feedback type="invalid">La fecha de fin es requerida</Form.Control.Feedback>
							</FloatingLabel>
						}
						{repeatOption === 'weekly' &&
							<Form.Group className='mb-3'>
								<Form.Label className='text-center w-100'>Dias de la semana</Form.Label>
								<div className='d-flex justify-content-around'>
									{availableWeekDays.map((day) => (
										<Button
											key={day}
											variant={selectedDays.includes(day) ? 'primary' : 'secondary'}
											value={day}
											onClick={handleSelectedDaysChange}
										>
											{day}
										</Button>
									))}
								</div>
							</Form.Group>
						}
						<Form.Group className="w-100">
							<Typeahead
								multiple
								id="wod-search"
								options={wods}
								placeholder="Seleccione un WOD"
								labelKey="name"
								selected={event.wods}
								onChange={(data) => onChangeEventData('wods', data)}
							/>
						</Form.Group>
						{event._id &&
							<Form.Group className="mb-3 col-3" controlId="isActive">
								<Form.Label>Esta activo?</Form.Label>
								<Form.Check
									type="switch"
									checked={event.isActive}
									onChange={(e) => onChangeEventData('isActive', e.target.checked)}
								></Form.Check>
							</Form.Group>}

					</Form>
				</Modal.Body>
				<Modal.Footer className='d-flex justify-content-between'>
					<button className='admin-button dark' onClick={onClose}>
						Cancelar
					</button>
					<button className='admin-button' onClick={event._id ? handleShowEdit : handleShowCreate}>
						{event._id ? 'Guardar cambios' : 'Crear clase'}
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
export default EventEditor;
