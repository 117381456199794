
export class Block {
	constructor(name, timeCap, rounds, type, wodExercises, restBetweenRounds, workTime, restTime) {
		this.name = name;
		this.timeCap = timeCap;
		this.rounds = rounds;
		this.type = type;
		this.wodExercises = wodExercises;
		this.restBetweenRounds = restBetweenRounds;
		this.workTime = workTime;
		this.restTime = restTime;
	}
	static create(data) {
		const { name, timeCap, rounds, type, wodExercises, restBetweenRounds, workTime, restTime } = data;
		return new Block(name, timeCap, rounds, type, wodExercises, restBetweenRounds, workTime, restTime);
	}
	static createEmptyWOD() {
		return new Block('', '', '', '', [], '', '', '');
	}

	// Add any methods if needed
	isValid() {
		let valid = false;
		const { name, type } = this;
		const typeFields = BlockFields[type];
		if(name && typeFields) {
			valid = Object.keys(typeFields).every(fieldKey => this.#validateField(typeFields[fieldKey], this[fieldKey]));
		}
		return valid;
	}
	getValidationErrors() {
		const { name, type } = this;
		const typeFields = BlockFields[type];
		const errors = [];
		if (!name) {
			errors.push('Nombre');
		}
		if (!typeFields) {
			errors.push('Tipo');
		} else {
			Object.keys(typeFields).forEach(fieldKey => {
				if (!this.#validateField(typeFields[fieldKey], this[fieldKey])) {
					errors.push(typeFields[fieldKey].leftLabel);
				}
			});
		}
		return errors;
	}


	#validateField(field, value) {
		return field.type === 'text' ? parseTimeStr(value) : !Number.isNaN(Number(value)) && Number(value) > -1;
	}
}
export const parseTimeStr = (timeStr) => {
	const [minutes, seconds] = timeStr.split(':').map(Number);
	if (Number.isNaN(minutes) || Number.isNaN(seconds)) {
		throw new Error('Invalid time format');
	}
	return minutes + seconds / 60;
};
const formatTime = (totalMinutes) => {
	const minutes = Math.floor(totalMinutes);
	const seconds = Math.round((totalMinutes - minutes) * 60);
	return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export const BlockFields = {
	AMRAP: {
		timeCap: {
			leftLabel: 'Tiempo límite (minutos)',
			type: 'text'
		},
		rounds: {
			leftLabel: 'Rounds',
			type: 'number'
		},
	},
	EMOM: {
		workTime: {
			leftLabel: 'Cada',
			rightLabel: 'minutos',
			type: 'text',
		},
		rounds: {
			leftLabel: 'Por',
			rightLabel: 'rounds',
			type: 'number'
		},
		timeCap: {
			leftLabel: 'Total',
			rightLabel: 'minutos',
			type: 'text',
			calculator: (wod) => {
				let total = '00:00';
				const { workTime, rounds } = wod;
				if (typeof workTime === 'string' && workTime.length === 5 && rounds > 0) {
					const parsedWorkTime = parseTimeStr(workTime);
					const parsedRounds = Number(rounds);

					if (!Number.isNaN(parsedWorkTime) && !Number.isNaN(parsedRounds)) {
						total = formatTime(parsedWorkTime * rounds);
					}
				}
				return total;
			}
		},

	},
	'FOR TIME': {
		timeCap: {
			leftLabel: 'Tiempo',
			rightLabel: 'minutos',
			type: 'text'
		},
		rounds: {
			leftLabel: 'Rondas',
			type: 'number'
		}
	},
	TABATA: {
		rounds: {
			leftLabel: 'Rondas',
			type: 'number'
		},
		workTime: {
			leftLabel: 'Trabajo',
			rightLabel: 'minutos',
			type: 'text'
		},
		restTime: {
			leftLabel: 'Descanso',
			rightLabel: 'minutos',
			type: 'text'
		},
		timeCap: {
			leftLabel: 'Total',
			rightLabel: 'minutos',
			type: 'text',
			calculator: (wod) => {

				let total = '00:00';
				const { workTime, restTime, rounds } = wod;
				
				if (typeof workTime === 'string' && typeof restTime === 'string' &&
					 workTime.length === 5 && restTime.length === 5 && rounds > 0) {
					const parsedWorkTime = parseTimeStr(workTime);
					const parsedRestTime = parseTimeStr(restTime);
					const parsedRounds = Number(rounds);

					if (!Number.isNaN(parsedWorkTime) && !Number.isNaN(parsedRestTime) && !Number.isNaN(parsedRounds)) {

						total = formatTime((parsedWorkTime + parsedRestTime) * rounds);
					}
				}
				return total;
			},
		}
	}
}
	;